import React from "react";
import { Navigate } from "react-router-dom";
import {
  SettingOutlined,
  MacCommandOutlined,
  ControlOutlined,
  EnvironmentOutlined,
  AreaChartOutlined,
  DeploymentUnitOutlined,
  SubnodeOutlined,
  TagsOutlined,
  DingtalkOutlined,
  NodeIndexOutlined,
  RadarChartOutlined,
  PieChartOutlined,
  PicLeftOutlined,
  PicCenterOutlined,
  MessageOutlined,
} from "@ant-design/icons";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ReactComponent as RoleManageIcon } from "../assets/icon/角色管理.svg";
import { ReactComponent as UserManageIcon } from "../assets/icon/用户管理.svg";
import { ReactComponent as CompManageIcon } from "../assets/icon/组件管理.svg";
import { ReactComponent as ProjectManageIcon } from "../assets/icon/项目管理.svg";
import { ReactComponent as OrganManageIcon } from "../assets/icon/机构管理.svg";
import { ReactComponent as DeviceManageIcon } from "../assets/icon/设备管理.svg";
import { ReactComponent as StandManageIcon } from "../assets/icon/机位管理.svg";
import { ReactComponent as ProductTypeIcon } from "../assets/icon/产品类型.svg";
import { ReactComponent as ProductBrandIcon } from "../assets/icon/产品品牌.svg";
import { ReactComponent as ProductModelIcon } from "../assets/icon/产品型号.svg";

// eslint-disable-next-line import/no-cycle
import LoginView from "../pages/common/login";
// eslint-disable-next-line import/no-cycle
import LayoutView from "../components/layout";

import PreviewMap from "../pages/basicMap/mapData/comp/PreviewMap";
import { MyInformation } from "../pages/personalCenter/myInformation";

// 没有使用默认导出
import { noDefaultExport } from "../utils/export";
import PageNotFound from "../pages/common/404";
// 权限路由类型
export interface routerItem {
  path: string;
  element: (() => Promise<any>) | React.ReactNode;
  meta: {
    id: number;
    hidden?: boolean;
    icon?: any;
    customIcon?: boolean;
    title: string;
  };
  children?: routerItem[];
  btns?: routerItem[];
  name?: string;
  noShow?: boolean; // 在左侧导航栏是否显示
}
// 非权限路由类型
export interface whiteItem {
  children?: whiteItem[];
  element: (() => Promise<any>) | React.ReactNode;
  name?: string;
  path: string;
}
// 不被权限控制的路由白名单
export const whiteRouter: Partial<routerItem>[] = [
  {
    path: "/",
    element: <Navigate to="/login" />,
  },
  {
    path: "/login",
    element: <LoginView />,
    name: "登录",
  },
  {
    path: "/404",
    element: <PageNotFound />,
  },
  {
    path: "/PreviewMap",
    name: "PreviewMap",
    element: <PreviewMap />,
  },
  {
    path: "/personalCenter",
    name: "personalCenter",
    element: <LayoutView />,
    noShow: true, // 在左侧导航栏不显示
    meta: {
      id: 214,
      icon: MacCommandOutlined,
      title: "个人中心",
    },
    children: [
      {
        path: "/personalCenter/myInformation",
        name: "MyInformation",
        element: <MyInformation />,
        meta: {
          id: 213,
          title: "个人中心",
          icon: RoleManageIcon,
          customIcon: true,
        },
      },
    ],
  },
];
// 配合后台权限的路由列表
export const router: routerItem[] = [
  // {
  //   path: "/dataCenter",
  //   name: "dataCenter",
  //   element: <LayoutView />,
  //   meta: {
  //     id: 54,
  //     icon: FundOutlined,
  //     title: "数据监控",
  //   },
  //   children: [
  //     {
  //       path: "/dataCenter/dataMonitor",
  //       name: "DataMonitor",
  //       element: () => import("../pages/dataCenter/dataMonitor"),
  //       meta: {
  //         id: 55,
  //         title: "系统数据",
  //         icon: DataMonitorIcon,
  //         customIcon: true,
  //       },
  //     },
  //   ],
  // },
  {
    path: "/deviceCenter",
    name: "deviceCenter",
    element: <LayoutView />,
    meta: {
      id: 380,
      icon: MacCommandOutlined,
      title: "设备中心",
    },
    children: [
      {
        path: "/deviceCenter/deviceManage",
        name: "DeviceManage",
        element: () => import("../pages/deviceCenter/deviceManage"),
        meta: {
          id: 381,
          title: "设备管理",
          icon: DeviceManageIcon,
          customIcon: true,
        },
      },
      {
        path: "/deviceCenter/deviceManage/details",
        name: "Details",
        noShow: true,
        element: () =>
          import("../pages/deviceCenter/deviceManage/comp/details"),
        meta: {
          id: 381,
          title: "设备管理 / 详情",
          icon: DeviceManageIcon,
          customIcon: true,
        },
      },
      {
        path: "/deviceCenter/standManage",
        name: "StandManage",
        element: () => import("../pages/deviceCenter/standManage"),
        meta: {
          id: 382,
          title: "机位管理",
          icon: StandManageIcon,
          customIcon: true,
        },
      },
      {
        path: "/deviceCenter/productModel",
        name: "ProductModel",
        element: () => import("../pages/deviceCenter/productModel"),
        meta: {
          id: 383,
          title: "产品型号",
          icon: ProductModelIcon,
          customIcon: true,
        },
      },
      {
        path: "/deviceCenter/productType",
        name: "ProductType",
        element: () => import("../pages/deviceCenter/productType"),
        meta: {
          id: 384,
          title: "产品类型",
          icon: ProductTypeIcon,
          customIcon: true,
        },
      },
      {
        path: "/deviceCenter/productBrand",
        name: "ProductBrand",
        element: () => import("../pages/deviceCenter/productBrand"),
        meta: {
          id: 385,
          title: "产品品牌",
          icon: ProductBrandIcon,
          customIcon: true,
        },
      },
    ],
  },
  {
    path: "/basicMap",
    name: "basicMap",
    element: <LayoutView />,
    meta: {
      id: 421,
      icon: EnvironmentOutlined,
      title: "基础地图",
    },
    children: [
      {
        path: "/basicMap/mapData",
        name: "MapData",
        element: () => import("../pages/basicMap/mapData"),
        meta: {
          id: 422,
          title: "地图数据",
          icon: AreaChartOutlined,
          customIcon: true,
        },
      },
      {
        path: "/basicMap/regionalStatistics",
        name: "regionalStatistics",
        element: () => import("../pages/basicMap/regionalStatistics"),
        meta: {
          id: 669,
          title: "区域统计成果",
          icon: PieChartOutlined,
          customIcon: true,
        },
      },
      {
        path: "/basicMap/annotationManagement",
        name: "annotationManagement",
        element: () => import("../pages/basicMap/annotationManagement"),
        meta: {
          id: 665,
          title: "标注管理",
          icon: PicLeftOutlined,
          customIcon: true,
        },
      },
      {
        path: "/basicMap/labelManagement",
        name: "labelManagement",
        element: () => import("../pages/basicMap/labelManagement"),
        meta: {
          id: 667,
          title: "标签管理",
          icon: PicCenterOutlined,
          customIcon: true,
        },
      },
    ],
  },
  // {
  //   path: "/PreviewMap",
  //   name: "PreviewMap",
  //   element: <PreviewMap />,
  //   noShow: true,
  //   meta: {
  //     id: 393,
  //     title: "地图预览",
  //     icon: DeviceManageIcon,
  //     customIcon: true,
  //   },
  // },
  {
    path: "/taskManage",
    name: "TaskManage",
    element: <LayoutView />,
    meta: {
      id: 427,
      title: "任务管理",
      icon: DeploymentUnitOutlined,
    },
    children: [
      {
        path: "/taskManage/taskList",
        name: "TaskList",
        element: () => import("../pages/taskManagement/taskList"),
        meta: {
          id: 428,
          title: "无人机任务",
          icon: OrganManageIcon,
          customIcon: true,
        },
      },
      {
        path: "/taskManage/taskList/details",
        name: "OrganManage",
        noShow: true,
        element: () =>
          noDefaultExport(
            import("../pages/taskManagement/taskList/comp/taskDetails"),
            "TaskDetails"
          ),
        meta: {
          id: 430,
          title: "无人机任务 / 详情",
          icon: OrganManageIcon,
          customIcon: true,
        },
      },
      {
        path: "/taskManage/eagleNestTask",
        name: "EagleNestTask",
        element: () => import("../pages/taskManagement/eagleNestTask"),
        meta: {
          id: 438,
          title: "鹰巢任务",
          icon: OrganManageIcon,
          customIcon: true,
        },
      },
      {
        path: "/taskManage/eagleNestTask/details",
        name: "EagleNestTaskDetails",
        noShow: true,
        element: () =>
          noDefaultExport(
            import(
              "../pages/taskManagement/eagleNestTask/comp/taskList/comp/taskDetails"
            ),
            "TaskDetails"
          ),
        meta: {
          id: 430,
          title: "鹰巢任务 / 详情",
          icon: OrganManageIcon,
          customIcon: true,
        },
      },
      {
        path: "/taskManage/processLabel",
        name: "ProcessLabel",
        element: () => import("../pages/taskManagement/processLabel"),
        meta: {
          id: 435,
          title: "流程节点",
          icon: SubnodeOutlined,
          customIcon: true,
        },
      },
      {
        path: "/taskManage/taskType",
        name: "TaskType",
        element: () => import("../pages/taskManagement/taskType"),
        meta: {
          id: 439,
          title: "任务流程",
          icon: TagsOutlined,
          customIcon: true,
        },
      },
    ],
  },
  {
    path: "/messageManage",
    name: "MessageManage",
    element: <LayoutView />,
    meta: {
      id: 697,
      title: "消息管理",
      icon: MessageOutlined,
    },
    children: [
      {
        path: "/messageManage/taskNotifications",
        name: "taskNotifications",
        element: () => import("../pages/messageManage/taskNotifications"),
        meta: {
          id: 698,
          title: "任务通知",
          icon: NodeIndexOutlined,
          customIcon: true,
        },
      },
    ],
  },
  {
    path: "/flightManagement",
    name: "FlightManagement",
    element: <LayoutView />,
    meta: {
      id: 444,
      title: "飞行管理",
      icon: DingtalkOutlined,
    },
    children: [
      {
        path: "/flightManagement/routeManagement",
        name: "RouteManagement",
        element: () => import("../pages/flightManagement/routeManagement"),
        meta: {
          id: 445,
          title: "航线管理",
          icon: NodeIndexOutlined,
          customIcon: true,
        },
      },
      {
        path: "/flightManagement/airspaceManagement",
        name: "AirspaceManagement",
        element: () => import("../pages/flightManagement/airspaceManagement"),
        meta: {
          id: 448,
          title: "空域管理",
          icon: RadarChartOutlined,
          customIcon: true,
        },
      },
      {
        path: "/flightManagement/earlyWarning",
        name: "EarlyWarning",
        element: () => import("../pages/flightManagement/earlyWarning"),
        meta: {
          id: 783,
          title: "预警设置",
          icon: RadarChartOutlined,
          customIcon: true,
        },
      },
    ],
  },
  {
    path: "/resourceManagement",
    name: "resourceManagement",
    element: <LayoutView />,
    meta: {
      id: 759,
      icon: MacCommandOutlined,
      title: "资源管理",
    },
    children: [
      {
        path: "/resourceManagement/resourceTypeList",
        name: "resourceTypeList",
        element: () => import("../pages/resourceManagement_/standManage"),
        meta: {
          id: 760,
          title: "资源类型",
          icon: TagsOutlined,
          customIcon: true,
        },
      },
      {
        path: "/resourceManagement/resourceList",
        name: "resourceList",
        element: () => import("../pages/resourceManagement_/deviceManage"),
        meta: {
          id: 764,
          title: "资源列表",
          icon: ControlOutlined,
          customIcon: true,
        },
      },
      {
        path: "/resourceManagement/resourceList/details",
        name: "Details",
        noShow: true,
        element: () =>
          import("../pages/resourceManagement_/deviceManage/comp/details"),
        meta: {
          id: 764,
          title: "资源列表 / 详情",
          icon: DeviceManageIcon,
          customIcon: true,
        },
      },
    ],
  },
  {
    path: "/systemSetting",
    name: "SystemSetting",
    element: <LayoutView />,
    meta: {
      id: 1,
      title: "系统管理",
      icon: SettingOutlined,
    },
    children: [
      {
        path: "/systemSetting/userManagement",
        name: "UserManagement",
        element: () => import("../pages/systemSetting/userManagement"),
        meta: {
          id: 405,
          title: "用户管理",
          icon: UserManageIcon,
        },
      },
      {
        path: "/systemSetting/roleManagement",
        name: "RoleManagement",
        element: () =>
          noDefaultExport(
            import("../pages/systemSetting/roleManagement"),
            "RoleManagement"
          ),
        meta: {
          id: 412,
          title: "角色管理",
          icon: RoleManageIcon,
          customIcon: true,
        },
      },
    ],
  },
  {
    path: "/superManage",
    name: "superManage",
    element: <LayoutView />,
    meta: {
      id: 246,
      icon: ControlOutlined,
      title: "超级管理",
    },
    children: [
      {
        path: "/superManage/componentsManage",
        name: "componentsManage",
        element: () => import("../pages/superManage/componentsManage"),
        meta: {
          id: 747,
          title: "组件管理",
          icon: CompManageIcon,
          customIcon: true,
        },
      },
      {
        path: "/superManage/authManage",
        name: "authManage",
        element: () => import("../pages/superManage/authManage"),
        meta: {
          id: 753,
          title: "授权管理",
          icon: CompManageIcon,
          customIcon: true,
        },
      },
      {
        path: "/superManage/applicationManage",
        name: "CompManage",
        element: () =>
          noDefaultExport(
            import("../pages/superManage/applicationManage"),
            "CompManage"
          ),
        meta: {
          id: 3,
          title: "应用管理",
          icon: CompManageIcon,
          customIcon: true,
        },
      },
      {
        path: "/superManage/organManage",
        name: "OrganManage",
        element: () =>
          noDefaultExport(
            import("../pages/systemSetting/organManage"),
            "OrganManage"
          ),
        meta: {
          id: 207,
          title: "机构管理",
          icon: OrganManageIcon,
          customIcon: true,
        },
      },
      {
        path: "/superManage/appConfig",
        name: "applicationConfig",
        element: () =>
          import("../pages/superManage/applicationManage/applicationConfig"),
        meta: {
          id: 10,
          title: "应用配置",
          hidden: true,
        },
      },
      {
        path: "/superManage/projectManage",
        name: "ProjectManage",
        element: () =>
          noDefaultExport(
            import("../pages/superManage/projectManage"),
            "ProjectManage"
          ),
        meta: {
          id: 206,
          title: "项目管理",
          icon: ProjectManageIcon,
          customIcon: true,
        },
      },
      {
        path: "/superManage/userManage",
        name: "UserManage",
        element: () =>
          noDefaultExport(
            import("../pages/superManage/userManage"),
            "UserManage"
          ),
        meta: {
          id: 5,
          title: "超级用户管理",
          icon: UserManageIcon,
          customIcon: true,
        },
      },
      {
        path: "/superManage/addService",
        name: "AddService",
        element: () => import("../pages/superManage/userManage/addService"),
        meta: {
          id: 790,
          title: "增值服务",
          hidden: true,
        },
      },
      {
        path: "/superManage/roleManage",
        name: "RoleManage",
        element: () =>
          noDefaultExport(
            import("../pages/superManage/roleManage"),
            "RoleManage"
          ),
        meta: {
          id: 4,
          title: "超级角色管理",
          icon: RoleManageIcon,
          customIcon: true,
        },
      },
      {
        path: "/superManage/VisualizedManagement",
        name: "PermissionManagement",
        element: () => import("../pages/systemSetting/VisualizedManagement"),
        meta: {
          id: 795,
          title: "可视化管理",
          icon: RoleManageIcon,
          customIcon: true,
        },
      },
    ],
  },
];
