import Axios from "../../request";
import { ResponseListTypeDms } from "../../interface";
import { UavPageProps, BrandAdd, BasicPropertyType } from "./interface";
import {
  ResType,
  ResSortieFlightType,
  FlightRecordsType,
  ImageRecordsType,
  ImageType,
  VideoType,
  PointType,
  MapType,
} from "../../../pages/deviceCenter/deviceManage/interface";

export class DeviceCenterAPI {
  // 添加新的资源类型
  static addBasicPropertyType = (data: BasicPropertyType): Promise<any> => {
    return Axios.post("/crm/basicProperty/addBasicPropertyType", data);
  };

  // 添加新的资源
  static addBasicPropertyInfo = (data: BasicPropertyType): Promise<any> => {
    return Axios.post("/crm/basicProperty/addBasicPropertyInfo", data);
  };

  // 添加新的资源
  static updateBasicPropertyInfo = (data: BasicPropertyType): Promise<any> => {
    return Axios.post("/crm/basicProperty/updateBasicPropertyInfo", data);
  };

  // 添加新的资源类型
  static updateBasicPropertyType = (data: BasicPropertyType): Promise<any> => {
    return Axios.post("/crm/basicProperty/updateBasicPropertyType", data);
  };

  // 所有应用列表
  static getUavPage = (
    data: UavPageProps
  ): Promise<ResponseListTypeDms<ResType>> => {
    return Axios.get("/dms/uav/page", { params: data });
  };

  // 飞行日志列表
  static getSortieFlightPage = (
    data: UavPageProps
  ): Promise<ResponseListTypeDms<ResSortieFlightType<FlightRecordsType>>> => {
    return Axios.get("/dms/sortie/flightLog", { params: data });
  };

  // 飞行日志-图片列表
  static getSortieImagePage = (
    data: UavPageProps
  ): Promise<ResponseListTypeDms<ImageRecordsType<ImageType>>> => {
    return Axios.get("/dms/sortie-image/page", { params: data });
  };

  // 资源列表
  static getPageBasicPropertyInfos: any = (params: any) =>
    Axios.get("/crm/basicProperty/getPageBasicPropertyInfos", { params });

  // 根据id获取详情
  static getBasicPropertyInfo: any = (params: any) =>
    Axios.get("/crm/basicProperty/getBasicPropertyInfo", { params });

  // 资源类型列表
  static getPageBasicPropertyTypes: any = (params: any) =>
    Axios.get("/crm/basicProperty/getPageBasicPropertyTypes", { params });

  // 飞行日志-视频列表
  static getSortieVideoPage = (
    data: UavPageProps
  ): Promise<ResponseListTypeDms<ImageRecordsType<VideoType>>> => {
    return Axios.get("/dms/sortie/history-video", { params: data });
  };

  // 飞行日志-历史轨迹
  static getSortieTrajectoryPage = (data: {
    flightSortie: string;
  }): Promise<ResponseListTypeDms<MapType<PointType>>> => {
    return Axios.get("/dms/sortie/data", { params: data });
  };

  static uavAdd = (data: any) => Axios.post("/dms/uav/add", data);

  static uavUpdate = (data: any) => Axios.put("/dms/uav/update", data);

  static uavDetail = (id: number) => Axios.get(`/dms/uav/detail/${id}`);

  static uavDelete = (id: number) => Axios.delete(`/dms/uav/delete/${id}`);

  static getStreamAddress = (
    data: UavPageProps
  ): Promise<ResponseListTypeDms<never>> => {
    return Axios.get("/dms/streamConfiguration/page", { params: data });
  };

  // 类型删除
  static deleteBasicPropertyInfo = (id: number) =>
    Axios.delete(`/crm/basicProperty/deleteBasicPropertyInfo?id=${id}`);

  // 流地址
  static streamAddressAdd = (data: any) =>
    Axios.post("/dms/streamConfiguration/add", data);

  static streamAddressUpdate = (data: any) =>
    Axios.put("/dms/streamConfiguration/update", data);

  static streamAddressDetail = (id: number) =>
    Axios.get(`/dms/streamConfiguration/findUrl?id=${id}`);

  static streamAddressDelete = (id: number) =>
    Axios.delete(`/dms/streamConfiguration/delete/${id}`);

  // 添加联网配置
  static upDateIp = (data: any) => Axios.put("/dms/uav/updateIp", data);

  static getSeatList = (
    data: UavPageProps
  ): Promise<ResponseListTypeDms<never>> => {
    return Axios.get("/dms/flightLocation/page", { params: data });
  };

  // 品牌分页列表   name page pageSize
  static brandList: any = (params: any) =>
    Axios.get("/dms/brand/page", { params });

  static brandOptions = () => Axios.get(`/dms/brand/list`);

  // 品牌添加
  static brandAdd = (data: BrandAdd): Promise<any> => {
    return Axios.post("/dms/brand/add", data);
  };

  // 品牌详情
  static brandDetail = (id: number) => Axios.get(`/dms/brand/detail${id}`);

  // 品牌编辑
  static brandUpdate = (data: any) => Axios.put("/dms/brand/update", data);

  // 品牌删除
  static brandDelete = (id: number) => Axios.delete(`/dms/brand/delete/${id}`);

  // 类型分页列表   name page pageSize
  static typeList: any = (params: any) =>
    Axios.get("/dms/cate/page", { params });

  // 类型添加
  static typeAdd = (data: any): Promise<any> => {
    return Axios.post("/dms/cate/add", data);
  };

  static typeListOption = () => Axios.get(`/dms/cate/list`);

  // 类型详情
  static typeDetail = (id: number) => Axios.get(`/dms/cate/detail/${id}`);

  // 类型编辑
  static typeUpdate = (data: any) => Axios.put("/dms/cate/update", data);

  // 类型删除
  static typeDeleter = (id: number) => Axios.get(`/dms/cate/delete${id}`);

  // 型号
  static modelListOption = (id?: number) =>
    Axios.get(`/dms/model/list${id ? `?cateId=${id}` : ""}`);

  // 产品型号列表/dms/model/add
  static getModelList = (
    data: UavPageProps
  ): Promise<ResponseListTypeDms<never>> => {
    return Axios.get("/dms/model/page", { params: data });
  };

  static modeAdd = (data: any) => Axios.post("/dms/model/add", data);

  static modeUpdate = (data: any) => Axios.put("/dms/model/update", data);

  static modeDelete = (id: number) => Axios.delete(`/dms/model/delete/${id}`);

  static typeDelete = (id: number): Promise<any> => {
    return Axios.delete(`/dms/cate/delete/${id}`);
  };

  // 联网机构
  static networkList: any = () => Axios.get("/crm/organization/tree");

  // 机位管理分页列表   name page pageSize
  static standList: any = (params: any) =>
    Axios.get("/dms/flightLocation/page", { params });

  // 类型添加
  static standAdd = (data: any): Promise<any> => {
    return Axios.post("/dms/flightLocation/add", data);
  };

  // 类型编辑
  static standUpdate = (data: any) =>
    Axios.put("/dms/flightLocation/update", data);

  // 类型删除
  static standDelete = (id: number) =>
    Axios.delete(`/dms/flightLocation/delete/${id}`);

  // 类型删除
  static deleteBasicPropertyType = (id: number) =>
    Axios.delete(`/crm/basicProperty/deleteBasicPropertyType?id=${id}`);

  static orgFlight = (orgId: number) =>
    Axios.get(`/dms/flightLocation/orgFlight?orgId=${orgId}`);
}
